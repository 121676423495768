import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'button'];

  connect() {}

  changeAttachType(event) {
    // This can break with change app/views/virtual_classrooms/partials/classroom_post_attaches/_fields.html.erb
    const attachmentRow = event.target.parentElement.parentElement.parentElement;

    const attach_type = attachmentRow.querySelector('select').value;
    const input_url = attachmentRow.querySelector('[type=url]');
    const input_file = attachmentRow.querySelector('[type=file]');

    // If needs one pdf file
    if (attach_type === 'pdf') {
      // Disable url input
      input_url.classList.add('d-none');
      input_url.disabled = true;

      // Enable file input
      input_file.classList.remove('d-none');
      input_file.disabled = false;
    } else {
      // Enable url input
      input_url.classList.remove('d-none');
      input_url.disabled = false;

      // Disable file input
      input_file.classList.add('d-none');
      input_file.disabled = true;
    }
  }

  addAttachment(event) {
    let regexp, time;
    let form = this.formTarget;

    // The attachment html is inside data-field from button target
    time = new Date().getTime();
    regexp = new RegExp(this.buttonTarget.getAttribute('data-id'), 'g');
    const attachmentHtml = this.buttonTarget.getAttribute('data-fields').replace(regexp, time);

    // Inset new attachment form
    form.parentElement
      .getElementsByClassName('fields')[0]
      .insertAdjacentHTML('beforeend', attachmentHtml);

    return event.preventDefault();
  }

  removeAttachment(event) {
    let target = $(event.target.parentElement);

    // Set ._destroy field to true
    target.prev('input[type=hidden]').val('1');
    target.closest('.col-md-6').hide();

    const form_fields = target.parent().get()[0].querySelectorAll('.form-control');

    for (let field of form_fields) {
      field.disabled = true;
    }

    return event.preventDefault();
  }
}
