import '../entrypoints/scheduled_exams_responses';

const summernoteUploadPath = '/summernote_upload/';

const sendFile = (file, toSummernote) => {
  const data = new FormData();
  data.append('upload[image]', file);
  return $.ajax({
    data,
    type: 'POST',
    url: summernoteUploadPath,
    cache: false,
    contentType: false,
    processData: false,
    success: ({ url, uploadID }) => {
      let img = document.createElement('IMG');
      img.src = url;
      img.setAttribute('id', 'sn-image-' + uploadID);
      return toSummernote.summernote('insertNode', img);
    },
  });
};

const deleteFile = (fileID) => {
  return $.ajax({
    type: 'DELETE',
    url: summernoteUploadPath + fileID,
    cache: false,
    contentType: false,
    processData: false,
  });
};
const defaultProps = {
  lang: 'pt-BR',
  placeholder: 'Escreva aqui...',
  tabDisable: true,
  lineHeights: ['0.2', '0.3', '0.4', '0.5', '0.6', '0.8', '1.0', '1.2', '1.4', '1.5', '2.0', '3.0'],
  toolbar: [
    ['style', ['style']],
    ['font', ['bold', 'underline', 'clear']],
    ['fontsize', ['fontsize']],
    ['fontname', ['fontname']],
    ['color', ['color']],
    ['height', ['height']],
    ['para', ['ul', 'ol', 'paragraph']],
    ['table', ['table']],
    ['insert', ['link', 'picture']],
    ['view', ['fullscreen', 'codeview', 'help']],
    ['extra', ['math']],
  ],
};

$(document).on('turbo:load', function () {
  // Principal summernote
  $('[data-provider="summernote"]').each(function () {
    $(this).summernote({
      ...defaultProps,
      height: 300,
      callbacks: {
        onImageUpload: (files) => {
          sendFile(files[0], $(this));
        },
        onMediaDelete: (target) => {
          const uploadID = target['0'].id.split('-').slice(-1)[0];
          if (uploadID) {
            deleteFile(uploadID);
          }
          target.remove();
        },
      },
    });
  });

  // Small summernote
  $('[data-provider="summernote_alternative"]').each(function () {
    $(this).summernote({
      ...defaultProps,
      height: 200,
      callbacks: {
        onImageUpload: (files) => {
          sendFile(files[0], $(this));
        },
        onMediaDelete: (target) => {
          const uploadID = target['0'].id.split('-').slice(-1)[0];
          if (uploadID) {
            deleteFile(uploadID);
          }
          target.remove();
        },
      },
    });
  });

  // Comment summernote
  $('[data-provider="summernote_comment"]').each(function () {
    $(this).summernote({
      ...defaultProps,
      height: 100,
      placeholder: 'Escreva um comentário...',
      callbacks: {
        onImageUpload: (files) => {
          sendFile(files[0], $(this));
        },
        onMediaDelete: (target) => {
          const uploadID = target['0'].id.split('-').slice(-1)[0];
          if (uploadID) {
            deleteFile(uploadID);
          }
          target.remove();
        },
      },
    });
  });

  // Student summernote
  $('[data-provider="summernote_student"]').each(function () {
    $(this).summernote({
      height: 200,
      lang: 'pt-BR',
      placeholder: 'Escreva aqui...',
      tabDisable: true,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['fontsize', ['fontsize']],
        ['fontname', ['fontname']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['extra', ['math']],
      ],
      callbacks: {
        onBlur: function () {
          updateDiscursiveResponse(this.value, this.dataset.responseId, this.dataset.updatePath);
        },
      },
    });
  });
});
