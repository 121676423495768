import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    // that are checker
    let checkElements = document.querySelectorAll('input[type="radio"]:checked');
    checkElements.forEach((element) => {
      let clickedElement = element.closest('.question-content');

      clickedElement.classList.add('correct-alternative');
    });
  }

  changeStyle(event) {
    // Removes styles all from elements
    let allElements = document.querySelectorAll('.question-content');

    allElements.forEach((element) => {
      element.classList.remove('correct-alternative');
    });

    // Add style for new elements
    let clickedElement = event.target.closest('.question-content');

    clickedElement.classList.add('correct-alternative');
  }
}
