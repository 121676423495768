// Tailwind
import './tailwind.css';

import '@hotwired/turbo-rails';
import '../controllers';
import '../modules/jquery';
import 'jquery-mask-plugin';

// TODO: Improves bundle SW-4685
import '../modules/moment'; // Only used for tempusdominus-bootstrap-4
import 'tempusdominus-bootstrap-4';

import Rails from '@rails/ujs';

Rails.start();

// Loads Boostrap
import * as bootstrap from 'bootstrap';

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl);
});

// Bootstrap Table
import 'bootstrap-table/src/bootstrap-table';
import 'bootstrap-table/src/extensions/fixed-columns/bootstrap-table-fixed-columns';
import 'bootstrap-table/src/extensions/sticky-header/bootstrap-table-sticky-header';

// Loads Selects
import select2 from 'select2';
import 'bootstrap-select';
import localizePTBR from '../select2/pt-BR';

select2($);
$.fn.select2.defaults.set('theme', 'bootstrap');
$.fn.select2.defaults.set('language', localizePTBR);

// TODO: Adds Pagy js
// import Pagy from "pagy-module";

// window.addEventListener("turbo:load", Pagy.init);

// Summernote
import 'summernote/dist/summernote-bs4';
import 'summernote/dist/lang/summernote-pt-BR';
import '../summernote/plugins/summernote-math';
import '../modules/summernote';

// Katex
import katex from 'katex';

window.katex = katex;

// Chart JS
import Chart from 'chart.js/auto';

window.Chart = Chart;

// Custom globals files
import '../modules/global';
import '../modules/utils';
import '../modules/grades';

// Show progress bar again [https://github.com/hotwired/turbo/issues/540]
const adapter = Turbo.navigator.delegate.adapter;
const progressBar = adapter.progressBar;
const session = Turbo.session;

let progressBarTimeout = null;

Turbo.setProgressBarDelay(400);
document.addEventListener('turbo:before-fetch-request', (event) => {
  const target = event.target;
  if (!(target instanceof HTMLElement)) {
    return;
  }

  if (!progressBarTimeout) {
    progressBar.setValue(0);
  }

  progressBarTimeout = window.setTimeout(() => progressBar.show(), session.progressBarDelay);
});

document.addEventListener('turbo:before-fetch-response', () => {
  if (progressBarTimeout) {
    window.clearTimeout(progressBarTimeout);
    progressBar.hide();
    progressBarTimeout = null;
  }
});
