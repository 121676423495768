import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['picker'];

  connect() {
    $(this.pickerTargets).datetimepicker({
      locale: 'pt-br',
      autoclose: true,
    });
  }
}
