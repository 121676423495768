import { Controller } from '@hotwired/stimulus';

// This stimulus only work with edit/update responses pages
export default class extends Controller {
  static values = { duration: String, createdAt: String, finish: String, updatePath: String };

  connect() {
    let beginTime = new Date(this.createdAtValue);
    let endTime = new Date(beginTime.getTime() + parseInt(this.durationValue) * 60000);
    let endDay = new Date(this.finishValue);

    window.countdown = this.countdown;

    if (endTime > endDay) {
      let idclock = setInterval(() => this.countdown(endDay, idclock), 1000);
    } else {
      let idclock = setInterval(() => this.countdown(endTime, idclock), 1000);
    }
  }

  // Clock - meetings
  countdown(meetingTime, idclock) {
    let now = new Date();
    let currentTime = now.getTime();

    let daysEl = document.getElementById('days');
    let hoursEl = document.getElementById('hours');
    let minutesEl = document.getElementById('minutes');
    let secondsEl = document.getElementById('seconds');

    if (!(daysEl && hoursEl && minutesEl && secondsEl)) {
      clearInterval(idclock);
      return;
    }

    let remTime = meetingTime.getTime() - currentTime;

    // When clock reaches 0 it changes the stundet response to finished
    if (remTime < 0) {
      daysEl.innerText = '0';

      hoursEl.textContent = '00';
      minutesEl.textContent = '00';
      secondsEl.textContent = '00';
      clearInterval(idclock);

      let params = {
        scheduled_exams_response: {
          finished: true,
        },
      };

      if (this.updatePathValue) {
        $.ajax({
          beforeSend: (xhr) => {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
          url: this.updatePathValue,
          type: 'patch',
          data: params,
          error: (httpResponse) => {
            alert(`Erro ao atualizar resposta: ${httpResponse.status}`);
          },
          success: () => {
            location.reload();
          },
        });
      }

      return;
    }

    let s = Math.floor(remTime / 1000);
    let m = Math.floor(s / 60);
    let h = Math.floor(m / 60);
    let d = Math.floor(h / 24);
    m %= 60;
    s %= 60;

    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;

    daysEl.textContent = d;
    daysEl.innerText = d;

    hoursEl.textContent = h;
    minutesEl.textContent = m;
    secondsEl.textContent = s;
  }
}
