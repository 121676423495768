import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['alternatives', 'student'];
  static values = { count: Number };

  initialize() {
    this.boundHandleSidebar = this.handleSidebar.bind(this);
  }

  connect() {
    this.CHANGES_TO_UPDATE = 10;
    this.requestQueue = [];
    this.sendingRequest = false;
    this.requestPath = '';
    // BoostrapTable Config
    this.table = $('#scores-table ');
    const maxHeight = $(window).height() * 0.75;

    this.table.bootstrapTable('destroy').bootstrapTable({
      ...(this.countValue > 7 && { height: maxHeight }),
      stickyHeader: true,
      classes: 'table table-borderless table-striped',
      fixedColumns: 'true',
      fixedNumber: 2,
    });

    $('.sidebar-close').on('click', this.boundHandleSidebar);

    setTimeout(() => {
      this.table.bootstrapTable('resetView');
    }, 1000);
    super.connect();
  }

  disconnect() {
    $('.sidebar-close').off('click', this.boundHandleSidebar);
  }

  handleSidebar() {
    // This resolve this bug: https://github.com/wenzhixin/bootstrap-table/issues/6006
    setTimeout(() => {
      this.table.bootstrapTable('resetView');
      setTimeout(() => {
        // Margin right scroll bar size
        const header = document.getElementsByClassName('fixed-table-header');
        header[0].style.setProperty('margin-right', '14px');
      }, 100);
    }, 600);
  }

  updateResponses() {
    if (this.requestQueue.length > 0 && !this.sendingRequest) {
      // Ajax Call
      const data = this.requestQueue;
      this.requestQueue = [];
      this.sendingRequest = true;
      $.ajax({
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        type: 'patch',
        dataType: 'json',
        url: this.requestPath,
        data: { responses: data },
        complete: () => {
          this.sendingRequest = false;
          if (this.requestQueue.length >= this.CHANGES_TO_UPDATE) {
            this.updateResponses();
          }
        },
        error: (e) => {
          location.reload();
          alert(`Error ao salvar resposta: ${e.responseJSON.error.message}`);
        },
      });
    }
  }

  updateScores(event) {
    const hideClass = 'd-none';
    const loadingElement = document.getElementById('loading-response');
    if (loadingElement) {
      loadingElement.classList.remove(hideClass);
    }

    this.requestPath = this.alternativesTarget.dataset.way;

    const responseAttrs = {
      student_id: document.getElementById(event.params.number).value,
      exams_question_id: document.getElementById(event.params.question).value,
      alternative: event.target.value,
    };

    // Check if the user changes the response of other student
    if (this.requestQueue.length !== 0) {
      const studentChanged =
        this.requestQueue[this.requestQueue.length - 1].student_id !== responseAttrs.student_id;
      if (studentChanged) {
        this.updateResponses();
      }
    }

    this.requestQueue.push(responseAttrs);

    if (this.requestQueue.length >= this.CHANGES_TO_UPDATE) {
      this.updateResponses();
    }
  }
}
